import pic1 from "../../../images/profile/small/pic1.jpg";
import avatar1 from "../../../images/avatar/5.png";
import avatar2 from "../../../images/avatar/1.png";

const jobsTable = {
   data: [
      [
         "Paul Byrd",
         "Chief Financial Officer (CFO)",
         "New York",
         "33",
         "2010/06/09",
         "$725,000",
      ],
      [
         "Gloria Little",
         "Systems Administrator",
         "New York",
         "47",
         "2009/04/10",
         "$237,500",
      ],
      [
         "Bradley Greer",
         "Software Engineer",
         "London",
         "51",
         "2012/10/13",
         "$132,000",
      ],
      [
         "Dai Rios",
         "Personnel Lead",
         "Edinburgh",
         "66",
         "2012/09/26",
         "$217,500",
      ],
      [
         "Jenette Caldwell",
         "Development Lead",
         "New York",
         "29",
         "2011/09/03",
         "$345,000",
      ],
      [
         "Yuri Berry",
         "Chief Marketing Officer (CMO)",
         "New York",
         "45",
         "2009/06/25",
         "$675,000",
      ],
      [
         "Caesar Vance",
         "Pre-Sales Support",
         "New York",
         "62",
         "2011/12/12",
         "$106,450",
      ],
      [
         "Doris Wilder",
         "Sales Assistant",
         "Sydney",
         "55",
         "2010/09/20",
         "$85,600",
      ],
      [
         "Angelica Ramos",
         "Chief Executive Officer (CEO)",
         "London",
         "53",
         "2009/10/09",
         "$1,200,000",
      ],
      ["Gavin Joyce", "Developer", "Edinburgh", "72", "2010/12/22", "$92,575"],
   ],
   columns: ["Name", "Position", "Office", "Age", "Start date", "Salary"],
};
const profileTable = {
   data: [
      [
         pic1,
         "Paul Byrd",
         "Chief Financial Officer (CFO)",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Gloria Little",
         "Systems Administrator",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Bradley Greer",
         "Software Engineer",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Dai Rios",
         "Personnel Lead",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Jenette Caldwell",
         "Development Lead",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Yuri Berry",
         "Chief Marketing Officer (CMO)",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Caesar Vance",
         "Pre-Sales Support",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Doris Wilder",
         "Sales Assistant",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Angelica Ramos",
         "Chief Executive Officer (CEO)",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
      [
         pic1,
         "Gavin Joyce",
         "Developer",
         "Male",
         "M.COM., P.H.D.",
         "123 456 7890",
         "info@example.com",
         "2011/04/25",
         "",
      ],
   ],
   columns: [
      "",
      "Name",
      "Department",
      "Gender",
      "Education",
      "Mobile",
      "Email",
      "Joining Date",
      "Action",
   ],
};
const feeTable = {
   data: [
      [
         "01",
         "Paul Byrd",
         "#3059",
         "Library",
         "Cash",
         "Paid",
         "2010/06/09",
         "$725,000",
      ],
      [
         "02",
         "Gloria Little",
         "#1721",
         "Library",
         "Cash",
         "Paid",
         "2009/04/10",
         "$237,500",
      ],
      [
         "03",
         "Bradley Greer",
         "#2558",
         "Library",
         "Cash",
         "Paid",
         "2012/10/13",
         "$132,000",
      ],
      [
         "04",
         "Dai Rios",
         "#2290",
         "Library",
         "Cash",
         "Paid",
         "2012/09/26",
         "$217,500",
      ],
      [
         "05",
         "Jenette Caldwell",
         "#1937",
         "Library",
         "Cash",
         "Paid",
         "2011/09/03",
         "$345,000",
      ],
      [
         "06",
         "Yuri Berry",
         "#6154",
         "Library",
         "Cash",
         "Paid",
         "2009/06/25",
         "$675,000",
      ],
      [
         "07",
         "Caesar Vance",
         "#8330",
         "Library",
         "Cash",
         "Paid",
         "2011/12/12",
         "$106,450",
      ],
      [
         "08",
         "Doris Wilder",
         "#3023",
         "Tuition",
         "Cheque",
         "Unpaid",
         "2010/09/20",
         "$85,600",
      ],
      [
         "09",
         "Angelica Ramos",
         "#5797",
         "Library",
         "Cash",
         "Paid",
         "2009/10/09",
         "$1,200,000",
      ],
      [
         "10",
         "Gavin Joyce",
         "#8822",
         "Annual",
         "Credit Card",
         "Panding",
         "2010/12/22",
         "$92,575",
      ],
   ],
   columns: [
      "Roll No",
      "Student Name",
      "Invoice number",
      "Fees Type ",
      "Payment Type ",
      "Status",
      "Date",
      "Amount",
   ],
};

const patientTable = {
   data: [
      [
         "",
         "#P-00001",
         "26/02/2020, 12:42 AM",
         "Paul Byrd",
         "Dr. Cedric",
         "Cold & Flu",
         "New Patient",
         "AB-001",
         "",
      ],
      [
         "",
         "#P-00002",

         "26/02/2020, 12:42 AM",
         "Gloria Little",
         "Dr. Cedric",
         "Cold & Flu",
         "In Treatment",
         "AB-002",
         "",
      ],
      [
         "",
         "#P-00003",

         "26/02/2020, 12:42 AM",
         "Bradley Greer",
         "Dr. Cedric",
         "Cold & Flu",
         " Recovered",
         "AB-003",
         "",
      ],
      [
         "",
         "#P-00004",
         "26/02/2020, 12:42 AM",
         "Bradley Greer",
         "Dr. Cedric",
         "Cold & Flu",
         " Recovered",
         "AB-003",
         "",
      ],
      [
         "",
         "#P-00005",
         "26/02/2020, 12:42 AM",
         "Jenette Caldwell",
         "Dr. Cedric",
         "Cold & Flu",
         "New Patient",
         "AB-005",
         "",
      ],
      [
         "",
         "#P-00006",
         "26/02/2020, 12:42 AM",
         "Yuri Berry",
         "Dr. Cedric",
         "Cold & Flu",
         "New Patient",
         "AB-006",
         "",
      ],
      [
         "",
         "#P-00007",
         "26/02/2020, 12:42 AM",
         "Caesar Vance",
         "Dr. Cedric",
         "Cold & Flu",
         "Recovered",
         "AB-007",
         "",
      ],
      [
         "",
         "#P-00008",
         "26/02/2020, 12:42 AM",
         "Doris Wilder",
         "Dr. Cedric",
         "Cold & Flu",
         "New Patient",
         "AB-008",
         "",
      ],
      [
         "",
         "#P-00009",
         "26/02/2020, 12:42 AM",
         "Angelica Ramos",
         "Dr. Cedric",
         "Cold & Flu",
         "New Patient",
         "AB-009",
         "",
      ],
      [
         "",
         "#P-00010",
         "26/02/2020, 12:42 AM",
         "Gavin Joyce",
         "Dr. Cedric",
         "Cold & Flu",
         "New Patient",
         "AB-010",
         "",
      ],
   ],
   columns: [
      "",
      "Patient ID",
      "Date Check in",
      "Patient Name",
      "Doctor Assgined",
      "Disease",
      "Status",
      "Room no",
      "Action",
   ],
};

const productData = {
   data: [
      [
         "Completed",
         [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
         "Completed",
         "$99",
         "",
      ],
      [
         "Processing",
         [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
         "Processing",
         "$99",
         "",
      ],
      [
         "",
         [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
         "On Hold",
         "$99",
         "",
      ],
      [
         "Pending",
         [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
         "Pending",
         "$99",
         "",
      ],
      [
         "Completed",
         [["#181"], [" by"], [" Ricky Antony"], [" ricky@example.com"]],
         "20/04/2020",
         "Ricky Antony, 2392 Main Avenue, Penasauka, New Jersey 02149   \n Via Flat Rate",
         "Completed",
         "$99",
         "",
      ],
   ],
   columns: ["Order", "Date", "Ship To", "Status", "Amount"],
};

const customers = {
   data: [
      [
         { one: "" },
         { two: { img: avatar1, name: "Ricky Antony" } },
         { three: "info@example.com" },
         { four: "(201) 200-1851	" },
         { five: "2392 Main Avenue, Penasauka" },
         { six: "30/03/2018" },
         { seven: "" },
      ],
      [
         { one: "" },
         { two: { img: avatar2, name: "Ricky Antony" } },
         { three: "info@example.com" },
         { four: "(201) 200-1851	" },
         { five: "2392 Main Avenue, Penasauka" },
         { six: "30/03/2018" },
         { seven: "" },
      ],
      [
         { one: "" },
         { two: { img: avatar1, name: "Ricky Antony" } },
         { three: "info@example.com" },
         { four: "(201) 200-1851	" },
         { five: "2392 Main Avenue, Penasauka" },
         { six: "30/03/2018" },
         { seven: "" },
      ],
      [
         { one: "" },
         { two: { img: avatar2, name: "Ricky Antony" } },
         { three: "info@example.com" },
         { four: "(201) 200-1851	" },
         { five: "2392 Main Avenue, Penasauka" },
         { six: "30/03/2018" },
         { seven: "" },
      ],
   ],
   columns: ["Name", "Email", "Phone", "Billing Address", "Joined", ""],
};

const listoffacilityTable = {
   data: [
      [
         "01",
         "Paul Byrd",
         "Witsand",
         "New",
         "Active"
      ],
      [
         "02",
         "Gloria Little",
         "Witsand",
         "Old",
         "InActive"
      ],
      [
         "03",
         "Bradley Greer",
         "Witsand",
         "New",
         "Active"
      ],
   ],
   columns: [
      "S No",
      "Name",
      "Location",
      "Type",
      "Status"
   ],
};

const listofuserTable = {
   data: [
      [
         "01",
         "Justen Oberbrunner", "Native American", "351 E International Airport Rd", "Anchorage", 99518, "(907) 561-4733", "(907) 561-4733", "YES", "House", 0.047, "Public", 5, 2, "1 st option", 
      ],
      [
         "02",
         "Alvis Balistreri", "Alaskan Native", "1601 Medfra St", "Anchorage", 99501, "(907) 279-1600", "(907) 279-1600", "YES", "House", 0.033, "Public", 3, 2, "3 option", 
      ],
      [
         "03",
         "Kaley Kemmer",  "Caucasian", "3721 B St", "Anchorage", 99503, "(907) 273-5800", "(907) 273-5800", "YES", "House", 0.073, "Private", 2, 3, "1 option", 
      ],
      [
         "04",
         "Gloria Bashirian", "African American", "432 3rd Ave", "Seward", 99664, "(907) 224-7072", "(907) 224-7072", "YES", "House", 0.023, "Private", 2, 1, "2 option", 
      ],
      [
         "05",
         "Lilla Doyle", "Asian", "1300 W Benson Blvd", "Anchorage", 99503, "(907) 269-5551", "(907) 269-5551", "YES", "Mobile home", 0.011, "Private", 1, 1, "3 option", 
      ],
      [
         "06",
         "Kailey Botsford", "Native American", "1401 S Seward Meridian Pkwy", "Wasilla", 99654, "(907) 376-1111", "(907) 376-1111", "YES", "Mobile home", 0.021, "Private", 1, 1, "1 option", 
      ],
   ],
   columns: [
      "S No",
      "Name",
      "Ethnicity",
      "Address",
      "City",
      "Zipcode",
      "Phone-Home",
      "Phone-Cellular",
      "Unicorp",
      "Type of Residence",
      "Property Size",
      "Water Source",
      "Occupants",
      "Bedrooms",
      "Sewage Disposal"
   ],
};

const listoforderTable = {
   data: [
      [
         "01",
         "Capetown",
         "Sanitize",
         "100",
         "100",
         "Open"
      ],
      [
         "02",
         "Capetown",
         "Sanitize",
         "100",
         "100",
         "Closed"
      ],
      [
         "03",
         "Capetown",
         "Sanitize",
         "100",
         "100",
         "InProgress"
      ],
   ],
   columns: [
      "S No",
      "Warehouse Location",
      "Product Name",
      "Quantity",
      "Total Cost",
      "Status"
   ],
};

const newusertabledata = [
   {
      "NAME": "Justen Oberbrunner", 
      "DATE": "07-06-2023", 
      "ETHINITICTY": "Native American", 
      "ADDRESS": "351 E International Airport Rd", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99518, 
      "PHONE - HOME": "(907) 561-4733", 
      "PHONE - CELLULAR": "(907) 561-4733", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.047, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 5, 
      "BEDROOMS": 2, 
      "SEWAGE DISPOSAL": "1 st option"}, 
   {
      "NAME": "Alvis Balistreri", 
      "DATE": "10-06-2023", 
      "ETHINITICTY": "Alaskan Native", 
      "ADDRESS": "1601 Medfra St", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "PHONE - HOME": "(907) 279-1600", 
      "PHONE - CELLULAR": "(907) 279-1600", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.033, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 3, 
      "BEDROOMS": 2, 
      "SEWAGE DISPOSAL": "3 option"}, 
   {
      "NAME": "Kaley Kemmer", 
      "DATE": "22-06-2023", 
      "ETHINITICTY": "Caucasian", 
      "ADDRESS": "3721 B St", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PHONE - HOME": "(907) 273-5800", 
      "PHONE - CELLULAR": "(907) 273-5800", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.073, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 2, 
      "BEDROOMS": 3, 
      "SEWAGE DISPOSAL": "1 option"}, 
   {
      "NAME": "Gloria Bashirian", 
      "DATE": "03-07-2023", 
      "ETHINITICTY": "African American", 
      "ADDRESS": "432 3rd Ave", 
      "CITY": "Seward", 
      "ZIP CODE": 99664, 
      "PHONE - HOME": "(907) 224-7072", 
      "PHONE - CELLULAR": "(907) 224-7072", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.023, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 2, 
      "BEDROOMS": 1, 
      "SEWAGE DISPOSAL": "2 option"}, 
   {
      "NAME": "Lilla Doyle", 
      "DATE": "13-07-2023", 
      "ETHINITICTY": "Asian", 
      "ADDRESS": "1300 W Benson Blvd", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PHONE - HOME": "(907) 269-5551", 
      "PHONE - CELLULAR": "(907) 269-5551", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "Mobile home", 
      "PROPERTY SIZE": 0.011, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 1, 
      "BEDROOMS": 1, 
      "SEWAGE DISPOSAL": "3 option"}, 
   {
      "NAME": "Kailey Botsford", 
      "DATE": "17-07-2023", 
      "ETHINITICTY": "Native American", 
      "ADDRESS": "1401 S Seward Meridian Pkwy", 
      "CITY": "Wasilla", 
      "ZIP CODE": 99654, 
      "PHONE - HOME": "(907) 376-1111", 
      "PHONE - CELLULAR": "(907) 376-1111", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "Mobile home", 
      "PROPERTY SIZE": 0.021, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 1, 
      "BEDROOMS": 1, 
      "SEWAGE DISPOSAL": "1 option"}, 
   {
      "NAME": "Camylle Mraz Jr.", 
      "DATE": "22-07-2023", 
      "ETHINITICTY": "Hawaiian", 
      "ADDRESS": "245 W 5th Ave #106", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "PHONE - HOME": "(907) 743-0572", 
      "PHONE - CELLULAR": "(907) 743-0572", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.054, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 2, 
      "BEDROOMS": 3, 
      "SEWAGE DISPOSAL": "1 option"}, 
   {
      "NAME": "Clementine Corkery", 
      "DATE": "05-08-2023", 
      "ETHINITICTY": "Native American", 
      "ADDRESS": "1825 Academy Dr", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99507, 
      "PHONE - HOME": "(907) 522-7090", 
      "PHONE - CELLULAR": "(907) 522-7090", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.064, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 3, 
      "BEDROOMS": 3, 
      "SEWAGE DISPOSAL": "2 option"}, 
   {
      "NAME": "Haylee Murray", 
      "DATE": "15-08-2023", 
      "ETHINITICTY": "Caucasian", 
      "ADDRESS": "32 College Rd", 
      "CITY": "Fairbanks", 
      "ZIP CODE": 99701, 
      "PHONE - HOME": "(907) 456-2171", 
      "PHONE - CELLULAR": "(907) 456-2171", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "Mobile home", 
      "PROPERTY SIZE": 0.025, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 1, 
      "BEDROOMS": 1, 
      "SEWAGE DISPOSAL": "2 option"}, 
   {
      "NAME": "Lexi Walter", 
      "DATE": "22-08-2023", 
      "ETHINITICTY": "Alaskan Native", 
      "ADDRESS": "3221 Denali St", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PHONE - HOME": "(907) 277-3464", 
      "PHONE - CELLULAR": "(907) 277-3464", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.034, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 3, 
      "BEDROOMS": 2, 
      "SEWAGE DISPOSAL": "3 option"}
   ]

const applicationformtable = [
   {
      "NAME": "Justen Oberbrunner", 
      "DATE": "07-06-2023", 
      "ETHINITICTY": "Native American", 
      "ADDRESS": "351 E International Airport Rd", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99518, 
      "PHONE - HOME": "(907) 561-4733", 
      "PHONE - CELLULAR": "(907) 561-4733", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.047, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 5, 
      "BEDROOMS": 2, 
      "STATUS": "rejected",
      "SPONSORS": "ARPA",
      "SEWAGE DISPOSAL": "1 st option"}, 
   {
      "NAME": "Alvis Balistreri", 
      "DATE": "10-06-2023", 
      "ETHINITICTY": "Alaskan Native", 
      "ADDRESS": "1601 Medfra St", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "PHONE - HOME": "(907) 279-1600", 
      "PHONE - CELLULAR": "(907) 279-1600", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.033, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 3, 
      "BEDROOMS": 2, 
      "STATUS": "rejected",
      "SPONSORS": "ARPA",
      "SEWAGE DISPOSAL": "3 option"}, 
   {
      "NAME": "Kaley Kemmer", 
      "DATE": "22-06-2023", 
      "ETHINITICTY": "Caucasian", 
      "ADDRESS": "3721 B St", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PHONE - HOME": "(907) 273-5800", 
      "PHONE - CELLULAR": "(907) 273-5800", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.073, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 2, 
      "BEDROOMS": 3, 
      "STATUS": "rejected",
      "SPONSORS": "USDA",
      "SEWAGE DISPOSAL": "1 option"}, 
   {
      "NAME": "Gloria Bashirian", 
      "DATE": "03-07-2023", 
      "ETHINITICTY": "African American", 
      "ADDRESS": "432 3rd Ave", 
      "CITY": "Seward", 
      "ZIP CODE": 99664, 
      "PHONE - HOME": "(907) 224-7072", 
      "PHONE - CELLULAR": "(907) 224-7072", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.023, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 2, 
      "BEDROOMS": 1, 
      "STATUS": "rejected",
      "SPONSORS": "USDA",
      "SEWAGE DISPOSAL": "2 option"}, 
   {
      "NAME": "Lilla Doyle", 
      "DATE": "13-07-2023", 
      "ETHINITICTY": "Asian", 
      "ADDRESS": "1300 W Benson Blvd", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PHONE - HOME": "(907) 269-5551", 
      "PHONE - CELLULAR": "(907) 269-5551", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "Mobile home", 
      "PROPERTY SIZE": 0.011, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 1, 
      "BEDROOMS": 1, 
      "STATUS": "rejected",
      "SPONSORS": "ARPA",
      "SEWAGE DISPOSAL": "3 option"}, 
   {
      "NAME": "Kailey Botsford", 
      "DATE": "17-07-2023", 
      "ETHINITICTY": "Native American", 
      "ADDRESS": "1401 S Seward Meridian Pkwy", 
      "CITY": "Wasilla", 
      "ZIP CODE": 99654, 
      "PHONE - HOME": "(907) 376-1111", 
      "PHONE - CELLULAR": "(907) 376-1111", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "Mobile home", 
      "PROPERTY SIZE": 0.021, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 1, 
      "BEDROOMS": 1, 
      "STATUS": "pending",
      "SPONSORS": "USDA",
      "SEWAGE DISPOSAL": "1 option"}, 
   {
      "NAME": "Camylle Mraz Jr.", 
      "DATE": "22-07-2023", 
      "ETHINITICTY": "Hawaiian", 
      "ADDRESS": "245 W 5th Ave #106", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "PHONE - HOME": "(907) 743-0572", 
      "PHONE - CELLULAR": "(907) 743-0572", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.054, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 2, 
      "BEDROOMS": 3, 
      "STATUS": "pending",
      "SPONSORS": "ARPA",
      "SEWAGE DISPOSAL": "1 option"}, 
   {
      "NAME": "Clementine Corkery", 
      "DATE": "05-08-2023", 
      "ETHINITICTY": "Native American", 
      "ADDRESS": "1825 Academy Dr", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99507, 
      "PHONE - HOME": "(907) 522-7090", 
      "PHONE - CELLULAR": "(907) 522-7090", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.064, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 3, 
      "BEDROOMS": 3, 
      "STATUS": "approved",
      "SPONSORS": "USDA",
      "SEWAGE DISPOSAL": "2 option"}, 
   {
      "NAME": "Haylee Murray", 
      "DATE": "15-08-2023", 
      "ETHINITICTY": "Caucasian", 
      "ADDRESS": "32 College Rd", 
      "CITY": "Fairbanks", 
      "ZIP CODE": 99701, 
      "PHONE - HOME": "(907) 456-2171", 
      "PHONE - CELLULAR": "(907) 456-2171", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "Mobile home", 
      "PROPERTY SIZE": 0.025, 
      "WATER SOURCE": "Private", 
      "OCCUPANTS": 1, 
      "BEDROOMS": 1, 
      "STATUS": "approved",
      "SPONSORS": "ARPA",
      "SEWAGE DISPOSAL": "2 option"}, 
   {
      "NAME": "Lexi Walter", 
      "DATE": "22-08-2023", 
      "ETHINITICTY": "Alaskan Native", 
      "ADDRESS": "3221 Denali St", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PHONE - HOME": "(907) 277-3464", 
      "PHONE - CELLULAR": "(907) 277-3464", 
      "UNICORP": "YES", 
      "TYPE OF RESIDENCE": "House", 
      "PROPERTY SIZE": 0.034, 
      "WATER SOURCE": "Public", 
      "OCCUPANTS": 3, 
      "BEDROOMS": 2, 
      "STATUS": "approved",
      "SPONSORS": "USDA",
      "SEWAGE DISPOSAL": "3 option"}
   ]

const soiltesttabledata = [
   {
      "FIRST NAME": "Justen ", 
      "LAST NAME": "Oberbrunner", 
      "ADDRESS": "351 E International Airport Rd", 
      "PHONE NO": "(907) 561-4733", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99518, 
      "PROFESSIONAL NAME": "Lucy McKenzie", 
      "ASSIGNED DATE": "13-08-2023", 
      "COST": 2300, 
      "STATUS": "Accepted"}, 
   {
      "FIRST NAME": "Alvis", 
      "LAST NAME": "Balistreri", 
      "ADDRESS": "1601 Medfra St", 
      "PHONE NO": "(907) 279-1600", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "PROFESSIONAL NAME": "Jazlyn Hermiston", 
      "ASSIGNED DATE": "17-08-2023", 
      "COST": 3400, 
      "STATUS": "Pending"}, 
   {
      "FIRST NAME": "Kaley ", 
      "LAST NAME": "Kemmer", 
      "ADDRESS": "3721 B St", 
      "PHONE NO": "(907) 273-5800", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PROFESSIONAL NAME": "Heaven Price", 
      "ASSIGNED DATE": "22-09-2023", 
      "COST": 5600, 
      "STATUS": "Rejected"}, 
   {
      "FIRST NAME": "Gloria ", 
      "LAST NAME": "Bashirian", 
      "ADDRESS": "432 3rd Ave", 
      "PHONE NO": "(907) 224-7072", 
      "STATE": "Alabama", 
      "CITY": "Seward", 
      "ZIP CODE": 99664, 
      "PROFESSIONAL NAME": "Randall Mitchell", 
      "ASSIGNED DATE": "27-09-2023", 
      "COST": 6500, 
      "STATUS": "Accepted"}, 
   {
      "FIRST NAME": "Lilla", 
      "LAST NAME": " Doyle", 
      "ADDRESS": "1300 W Benson Blvd", 
      "PHONE NO": "(907) 269-5551", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "PROFESSIONAL NAME": "Lucy McKenzie", 
      "ASSIGNED DATE": "04-10-2023", 
      "COST": 7800, 
      "STATUS": "Accepted"}, 
   {
      "FIRST NAME": "Kailey ", 
      "LAST NAME": "Botsford", 
      "ADDRESS": "1401 S Seward Meridian Pkwy", 
      "PHONE NO": "(907) 376-1111", 
      "STATE": "Alabama", 
      "CITY": "Wasilla", 
      "ZIP CODE": 99654, 
      "PROFESSIONAL NAME": "Randall Mitchell", 
      "ASSIGNED DATE": "07-10-2023", 
      "COST": 8700, 
      "STATUS": "Rejected"}
   ]

const engineerdesigntabledate = [
   {
      "FIRST NAME": "Camylle ", 
      "LAST NAME": "Mraz Jr.", 
      "ADDRESS": "245 W 5th Ave #106", 
      "PHONE NO": "(907) 743-0572", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "SCHEMATICS": "Schematics 1", 
      "ENGINEER": "Hank", 
      "ASSIGNED DATE": "13-07-2023", 
      "COST": 4500, 
      "STATUS": "Approved"}, 
   {
      "FIRST NAME": "Clementine ", 
      "LAST NAME": "Corkery", 
      "ADDRESS": "1825 Academy Dr", 
      "PHONE NO": "(907) 522-7090", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99507, 
      "SCHEMATICS": "Schematics 1", 
      "ENGINEER": "Jane", 
      "ASSIGNED DATE": "17-07-2023", 
      "COST": 5400, 
      "STATUS": "Approved"}, 
   {
      "FIRST NAME": "Haylee ", 
      "LAST NAME": "Murray", 
      "ADDRESS": "32 College Rd", 
      "PHONE NO": "(907) 456-2171", 
      "STATE": "Alabama", 
      "CITY": "Fairbanks", 
      "ZIP CODE": 99701, 
      "SCHEMATICS": "Schematics 2", 
      "ENGINEER": "Hank", 
      "ASSIGNED DATE": "22-07-2023", 
      "COST": 3400, 
      "STATUS": "Denied"}, 
   {
      "FIRST NAME": "Lexi ", 
      "LAST NAME": "Walter", 
      "ADDRESS": "3221 Denali St", 
      "PHONE NO": "(907) 277-3464", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "SCHEMATICS": "Schematics 3", 
      "ENGINEER": "Jane", 
      "ASSIGNED DATE": "05-08-2023", 
      "COST": 6400, 
      "STATUS": "Approved"}, 
   {
      "FIRST NAME": "Gloria ", 
      "LAST NAME": "Bashirian", 
      "ADDRESS": "432 3rd Ave", 
      "PHONE NO": "(907) 224-7072", 
      "STATE": "Alabama", 
      "CITY": "Seward", 
      "ZIP CODE": 99664, 
      "SCHEMATICS": "Schematics 2", 
      "ENGINEER": "Hank", 
      "ASSIGNED DATE": "15-08-2023", 
      "COST": 2300, 
      "STATUS": "Denied"}
   ]

const contractortabledata = [
   {
      "FIRST NAME": "Milton", 
      "LAST NAME": "Murray", 
      "ADDRESS": "2716 E Tudor Rd", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99507, 
      "PHONE NO": "(907) 868-8885", 
      "EMAIL": "miltonmurray@gmail.com", 
      "SCOPE OF WORK": "Electrical", 
      "COMPANY NAME": "Elixirpower", 
      "COST": 46000}, 
   {
      "FIRST NAME": "Jayme", 
      "LAST NAME": "Kihn", 
      "ADDRESS": "3950 Debarr Rd", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99508, 
      "PHONE NO": "(907) 337-1311", 
      "EMAIL": "jaymekihn@gmail.com", 
      "SCOPE OF WORK": "Plumbing", 
      "COMPANY NAME": "Waterworks", 
      "COST": 50000}, 
   {
      "FIRST NAME": "Hank", 
      "LAST NAME": "Wyman", 
      "ADDRESS": "150 E Benson Blvd", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99503, 
      "PHONE NO": "(907) 562-8195", 
      "EMAIL": "hankwyman@gmail.com", 
      "SCOPE OF WORK": "Engineer", 
      "COMPANY NAME": "Construx", 
      "COST": 34500}, 
   {
      "FIRST NAME": "Annie", 
      "LAST NAME": "Hettinger", 
      "ADDRESS": "3700 Old Seward Hwy", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99503, 
      "PHONE NO": "(907) 561-5173", 
      "EMAIL": "anniehetti@gmail.com", 
      "SCOPE OF WORK": "Electrical", 
      "COMPANY NAME": "Streamvolt", 
      "COST": 67000}, 
   {
      "FIRST NAME": "Clementina", 
      "LAST NAME": "Schinner", 
      "ADDRESS": "2210 E Northern Lights Blvd", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99508, 
      "PHONE NO": "(907) 333-8000", 
      "EMAIL": "clementinasch@gmail.com", 
      "SCOPE OF WORK": "Plumbing", 
      "COMPANY NAME": "Waterfixx", 
      "COST": 45000}, 
   {
      "FIRST NAME": "Jane", 
      "LAST NAME": "Koch", 
      "ADDRESS": "2210 E Northern Lights Blvd", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99508, 
      "PHONE NO": "(907) 332-2030", 
      "EMAIL": "janekoch@gmail.com", 
      "SCOPE OF WORK": "Engineer", 
      "COMPANY NAME": "Brickbuilds", 
      "COST": 64000}, 
   {
      "FIRST NAME": "Reyes", 
      "LAST NAME": "Wehner", 
      "ADDRESS": "6623 Brayton Dr", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99507, 
      "PHONE NO": "(907) 677-8103", 
      "EMAIL": "reyesweh@gmail.com", 
      "SCOPE OF WORK": "Electrical", 
      "COMPANY NAME": "Electraco", 
      "COST": 34000}, 
   {
      "FIRST NAME": "Desmond", 
      "LAST NAME": "Von ", 
      "ADDRESS": "245 W 5th Ave #106", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99501, 
      "PHONE NO": "(907) 743-0572", 
      "EMAIL": "desmondvon@gmail.com", 
      "SCOPE OF WORK": "Plumbing", 
      "COMPANY NAME": "Fluidflow", 
      "COST": 23000}, 
   {
      "FIRST NAME": "Barry", 
      "LAST NAME": "McKenzie", 
      "ADDRESS": "1401 S Seward Meridian Pkwy", 
      "CITY": "Wasilla", 
      "STATE": "Alabama", 
      "ZIP CODE": 99654, 
      "PHONE NO": "(907) 376-1111", 
      "EMAIL": "barrymck@gmail.com", 
      "SCOPE OF WORK": "Electrical", 
      "COMPANY NAME": "Megavolt", 
      "COST": 64000}, 
   {
      "FIRST NAME": "Lawrence ", 
      "LAST NAME": "Bauch", 
      "ADDRESS": "825 W 6th Ave", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99501, 
      "PHONE NO": "(907) 276-6388", 
      "EMAIL": "lawrencebauch@gmail.com", 
      "SCOPE OF WORK": "Installer", 
      "COMPANY NAME": "Installit", 
      "COST": 25000}, 
   {
      "FIRST NAME": "Berry", 
      "LAST NAME": "Kreiger", 
      "ADDRESS": "2917 Spenard Rd", 
      "CITY": "Anchorage", 
      "STATE": "Alabama", 
      "ZIP CODE": 99503, 
      "PHONE NO": "(907) 272-2666", 
      "EMAIL": "berrykrei@gmail.com", 
      "SCOPE OF WORK": "Installer", 
      "COMPANY NAME": "GalaxyInstall", 
      "COST": 46000}
   ]


const InstallationTableData = [
   {
      "FIRST NAME": "Justen ", 
      "LAST NAME": "Oberbrunner", 
      "ADDRESS": "351 E International Airport Rd", 
      "PHONE NO": "(907) 561-4733", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99518, 
      "ASSIGN TO": "Lawrence ", 
      "DATE": "13-07-2023", 
      "COST": 4500, 
      "STATUS": "Approved"}, 
   {
      "FIRST NAME": "Alvis", 
      "LAST NAME": "Balistreri", 
      "ADDRESS": "1601 Medfra St", 
      "PHONE NO": "(907) 279-1600", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "ASSIGN TO": "Berry", 
      "DATE": "17-07-2023", 
      "COST": 5400, 
      "STATUS": "Approved"}, 
   {
      "FIRST NAME": "Kaley ", 
      "LAST NAME": "Kemmer", 
      "ADDRESS": "3721 B St", 
      "PHONE NO": "(907) 273-5800", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "ASSIGN TO": "Berry", 
      "DATE": "22-07-2023", 
      "COST": 3400, 
      "STATUS": "Denied"}, 
   {
      "FIRST NAME": "Gloria ", 
      "LAST NAME": "Bashirian", 
      "ADDRESS": "432 3rd Ave", 
      "PHONE NO": "(907) 224-7072", 
      "STATE": "Alabama", 
      "CITY": "Seward", 
      "ZIP CODE": 99664, 
      "ASSIGN TO": "Lawrence ", 
      "DATE": "05-08-2023", 
      "COST": 6400, 
      "STATUS": "Approved"}, 
   {
      "FIRST NAME": "Lilla", 
      "LAST NAME": " Doyle", 
      "ADDRESS": "1300 W Benson Blvd", 
      "PHONE NO": "(907) 269-5551", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "ASSIGN TO": "Lawrence ", 
      "DATE": "15-08-2023", 
      "COST": 2300, 
      "STATUS": "Denied"}, 
   {
      "FIRST NAME": "Kailey ", 
      "LAST NAME": "Botsford", 
      "ADDRESS": "1401 S Seward Meridian Pkwy", 
      "PHONE NO": "(907) 376-1111", 
      "STATE": "Alabama", 
      "CITY": "Wasilla", 
      "ZIP CODE": 99654, 
      "ASSIGN TO": "Berry", 
      "DATE": "22-08-2023", 
      "COST": 5600, 
      "STATUS": "Approved"}
   ]

const electricalplumbingtabledata = [
   {
      "FIRST NAME": "Camylle ", 
      "LAST NAME": "Mraz Jr.", 
      "ADDRESS": "245 W 5th Ave #106", 
      "PHONE NO": "(907) 743-0572", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99501, 
      "ELECTRICAL CONTRACTOR": "Milton", 
      "DATE": "13-07-2023", 
      "COST": 2300, 
      "PLUMBING CONTRACTOR": "Jayme", 
      "DATE": "13-07-2023", 
      "COST": 2300}, 
   {
      "FIRST NAME": "Clementine ", 
      "LAST NAME": "Corkery", 
      "ADDRESS": "1825 Academy Dr", 
      "PHONE NO": "(907) 522-7090", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99507, 
      "ELECTRICAL CONTRACTOR": "Annie", 
      "DATE": "17-07-2023", 
      "COST": 3400, 
      "PLUMBING CONTRACTOR": "Clementina", 
      "DATE": "17-07-2023", 
      "COST": 3400}, 
   {
      "FIRST NAME": "Haylee ", 
      "LAST NAME": "Murray", 
      "ADDRESS": "32 College Rd", 
      "PHONE NO": "(907) 456-2171", 
      "STATE": "Alabama", 
      "CITY": "Fairbanks", 
      "ZIP CODE": 99701, 
      "ELECTRICAL CONTRACTOR": "Reyes", 
      "DATE": "22-07-2023", 
      "COST": 5600, 
      "PLUMBING CONTRACTOR": "Desmond", 
      "DATE": "22-07-2023", 
      "COST": 5600}, 
   {
      "FIRST NAME": "Lexi ", 
      "LAST NAME": "Walter", 
      "ADDRESS": "3221 Denali St", 
      "PHONE NO": "(907) 277-3464", 
      "STATE": "Alabama", 
      "CITY": "Anchorage", 
      "ZIP CODE": 99503, 
      "ELECTRICAL CONTRACTOR": "Barry", 
      "DATE": "05-08-2023", 
      "COST": 6500, 
      "PLUMBING CONTRACTOR": "Clementina", 
      "DATE": "05-08-2023", 
      "COST": 6500}, 
   {
      "FIRST NAME": "Gloria ", 
      "LAST NAME": "Bashirian", 
      "ADDRESS": "432 3rd Ave", 
      "PHONE NO": "(907) 224-7072", 
      "STATE": "Alabama", 
      "CITY": "Seward", 
      "ZIP CODE": 99664, 
      "ELECTRICAL CONTRACTOR": "Milton", 
      "DATE": "15-08-2023", 
      "COST": 7800, 
      "PLUMBING CONTRACTOR": "Jayme", 
      "DATE": "15-08-2023", 
      "COST": 7800}
   ]


   const schematictabledata = [
      {"schematic":"Conventional system","system": "Schematic 1"},
      {"schematic":"Engineering system","system": "Schematic 2"},
      {"schematic":"Advanced engineered system","system": "Schematic 3"},
      // {"schematic":"Schematic 4","system": "Advance engg. System 2"},
   ]

   const projectabledata = [
      {
         "PROJECT NAME": "ALDAS", 
         "LOCATION": "NY", 
         "COST": "$5000", 
         "PAID": "$2000", 
         "PENDING": "$3000"}, 
      {
         "PROJECT NAME": "LDAP", 
         "LOCATION": "NJ", 
         "COST": "$10000", 
         "PAID": "$4000", 
         "PENDING": "$6000"}, 
      {
         "PROJECT NAME": "ARIBA", 
         "LOCATION": "NY", 
         "COST": "$3000", 
         "PAID": "$1000", 
         "PENDING": "$2000"}, 
      {
         "PROJECT NAME": "KELC", 
         "LOCATION": "NJ", 
         "COST": "$5000", 
         "PAID": "$1000", 
         "PENDING": "$4000"}, 
      {
         "PROJECT NAME": "YUXC", 
         "LOCATION": "NY", 
         "COST": "$8000", 
         "PAID": "$3000", 
         "PENDING": "$5000"}, 
      {
         "PROJECT NAME": "COLX", 
         "LOCATION": "NJ", 
         "COST": "$7000", 
         "PAID": "$2000", 
         "PENDING": "$5000"}
      ]

const paymentusertabledata = [
   {
      "NAME": "Justen Oberbrunner",
      "PROJECT NAME": "COLX",
      "LAST PAID AMOUNT": "$60", 
      "LAST PAID DATE": "03-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "12-09-2023", 
      "OVERDUE AMOUNT": "$100", 
      "OVERDUE DATE": "23-08-2023", 
      "PAYMENT METHOD": "Money order", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$100", 
      "WHEN": "16-08-2023"}, 
   {
      "NAME": "Alvis Balistreri",
      "PROJECT NAME": "YUXC",
      "LAST PAID AMOUNT": "$80", 
      "LAST PAID DATE": "04-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "13-09-2023", 
      "OVERDUE AMOUNT": "$40", 
      "OVERDUE DATE": "24-08-2023", 
      "PAYMENT METHOD": "Cheque", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$40", 
      "WHEN": "17-08-2023"}, 
   {
      "NAME": "Kaley Kemmer",
      "PROJECT NAME": "COLX",
      "LAST PAID AMOUNT": "$20", 
      "LAST PAID DATE": "05-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "14-09-2023", 
      "OVERDUE AMOUNT": "$60", 
      "OVERDUE DATE": "25-08-2023", 
      "PAYMENT METHOD": "Cash", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$60", 
      "WHEN": "18-08-2023"}, 
   {
      "NAME": "Gloria Bashirian",
      "PROJECT NAME": "YUXC",
      "LAST PAID AMOUNT": "$20", 
      "LAST PAID DATE": "06-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "15-09-2023", 
      "OVERDUE AMOUNT": "$100", 
      "OVERDUE DATE": "26-08-2023", 
      "PAYMENT METHOD": "Online transfer", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$100", 
      "WHEN": "19-08-2023"}, 
   {
      "NAME": "Lilla Doyle",
      "PROJECT NAME": "KELC",
      "LAST PAID AMOUNT": "$40", 
      "LAST PAID DATE": "07-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "16-09-2023", 
      "OVERDUE AMOUNT": "$180", 
      "OVERDUE DATE": "27-08-2023", 
      "PAYMENT METHOD": "Others", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$180", 
      "WHEN": "20-08-2023"}, 
   {
      "NAME": "Kailey Botsford",
      "PROJECT NAME": "ARIBA",
      "LAST PAID AMOUNT": "$100", 
      "LAST PAID DATE": "08-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "17-09-2023", 
      "OVERDUE AMOUNT": "$80", 
      "OVERDUE DATE": "28-08-2023", 
      "PAYMENT METHOD": "Money order", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$80", 
      "WHEN": "21-08-2023"}, 
   {
      "NAME": "Camylle Mraz Jr.",
      "PROJECT NAME": "LDAP",
      "LAST PAID AMOUNT": "$160", 
      "LAST PAID DATE": "09-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "18-09-2023", 
      "OVERDUE AMOUNT": "$20", 
      "OVERDUE DATE": "29-08-2023", 
      "PAYMENT METHOD": "Cheque", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$20", 
      "WHEN": "22-08-2023"}, 
   {
      "NAME": "Camylle Mraz Jr.",
      "PROJECT NAME": "ALDAS",
      "LAST PAID AMOUNT": "$180", 
      "LAST PAID DATE": "10-08-2023", 
      "CURRENT DUE AMOUNT": "$20", 
      "CURRENT DUE DATE": "19-09-2023", 
      "OVERDUE AMOUNT": "$80", 
      "OVERDUE DATE": "30-08-2023", 
      "PAYMENT METHOD": "Cash", 
      "CURRENT DUE": "$20", 
      "OVERDUE": "$80", 
      "WHEN": "23-08-2023"}
   ]

   const paymentcontractortable = [
      {
         "FIRST NAME": "Milton", 
         "LAST NAME": "Murray", 
         "ADDRESS": "2716 E Tudor Rd", 
         "PHONE NO": "(907) 868-8885", 
         "ZIP CODE": 99507, 
         "EMAIL ID": "miltonmurray@gmail.com", 
         "LAST PAID AMOUNT": "$600", 
         "LAST PAID DATE": "03-08-2023", 
         "BALANCE": "$100", 
         "OVERDUE DATE": "23-08-2023", 
         "PAYMENT METHOD": "Money order", 
         "PAYMENT": "$100", 
         "WHEN": "16-08-2023"}, 
      {
         "FIRST NAME": "Jayme", 
         "LAST NAME": "Kihn", 
         "ADDRESS": "3950 Debarr Rd", 
         "PHONE NO": "(907) 337-1311", 
         "ZIP CODE": 99508, 
         "EMAIL ID": "jaymekihn@gmail.com", 
         "LAST PAID AMOUNT": "$800", 
         "LAST PAID DATE": "04-08-2023", 
         "BALANCE": "$40", 
         "OVERDUE DATE": "24-08-2023", 
         "PAYMENT METHOD": "Cheque", 
         "PAYMENT": "$200", 
         "WHEN": "17-08-2023"}, 
      {
         "FIRST NAME": "Hank", 
         "LAST NAME": "Wyman", 
         "ADDRESS": "150 E Benson Blvd", 
         "PHONE NO": "(907) 562-8195", 
         "ZIP CODE": 99503, 
         "EMAIL ID": "hankwyman@gmail.com", 
         "LAST PAID AMOUNT": "$200", 
         "LAST PAID DATE": "05-08-2023", 
         "BALANCE": "$60", 
         "OVERDUE DATE": "25-08-2023", 
         "PAYMENT METHOD": "Cash", 
         "PAYMENT": "$50", 
         "WHEN": "18-08-2023"}, 
      {
         "FIRST NAME": "Annie", 
         "LAST NAME": "Hettinger", 
         "ADDRESS": "3700 Old Seward Hwy", 
         "PHONE NO": "(907) 561-5173", 
         "ZIP CODE": 99503, 
         "EMAIL ID": "anniehetti@gmail.com", 
         "LAST PAID AMOUNT": "$200", 
         "LAST PAID DATE": "06-08-2023", 
         "BALANCE": "$100", 
         "OVERDUE DATE": "26-08-2023", 
         "PAYMENT METHOD": "Online transfer", 
         "PAYMENT": "$30", 
         "WHEN": "19-08-2023"}, 
      {
         "FIRST NAME": "Clementina", 
         "LAST NAME": "Schinner", 
         "ADDRESS": "2210 E Northern Lights Blvd", 
         "PHONE NO": "(907) 333-8000", 
         "ZIP CODE": 99508, 
         "EMAIL ID": "clementinasch@gmail.com", 
         "LAST PAID AMOUNT": "$400", 
         "LAST PAID DATE": "07-08-2023", 
         "BALANCE": "$180", 
         "OVERDUE DATE": "27-08-2023", 
         "PAYMENT METHOD": "Others", 
         "PAYMENT": "$70", 
         "WHEN": "20-08-2023"}, 
      {
         "FIRST NAME": "Jane", 
         "LAST NAME": "Koch", 
         "ADDRESS": "2210 E Northern Lights Blvd", 
         "PHONE NO": "(907) 332-2030", 
         "ZIP CODE": 99508, 
         "EMAIL ID": "janekoch@gmail.com", 
         "LAST PAID AMOUNT": "$1000", 
         "LAST PAID DATE": "08-08-2023", 
         "BALANCE": "$80", 
         "OVERDUE DATE": "28-08-2023", 
         "PAYMENT METHOD": "Money order", 
         "PAYMENT": "$100", 
         "WHEN": "21-08-2023"}, 
      {
         "FIRST NAME": "Reyes", 
         "LAST NAME": "Wehner", 
         "ADDRESS": "6623 Brayton Dr", 
         "PHONE NO": "(907) 677-8103", 
         "ZIP CODE": 99507, 
         "EMAIL ID": "reyesweh@gmail.com", 
         "LAST PAID AMOUNT": "$1600", 
         "LAST PAID DATE": "09-08-2023", 
         "BALANCE": "$20", 
         "OVERDUE DATE": "29-08-2023", 
         "PAYMENT METHOD": "Cheque", 
         "PAYMENT": "$150", 
         "WHEN": "22-08-2023"}
      ]

const data = {
   jobsTable,
   profileTable,
   feeTable,
   patientTable,
   productData,
   customers,
   listoffacilityTable,
   listofuserTable,
   listoforderTable,
   newusertabledata,
   applicationformtable,
   soiltesttabledata,
   contractortabledata,
   engineerdesigntabledate,
   InstallationTableData,
   electricalplumbingtabledata,
   schematictabledata,
   projectabledata,
   paymentusertabledata,
   paymentcontractortable
};

export default data;
