import React, { Fragment, useEffect, useState } from "react";
import PageTItle from "../../layouts/PageTitle";
import { Row, Col, Card, Tab, Nav } from "react-bootstrap";
import data from "../../components/table/tableData";
import {
    Table,
    Pagination
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import * as apiServices from '../../../services/apiServices'
import { setValue } from "@syncfusion/ej2-base";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";
import HeaderPrint from "./HeaderPrint";
import { getDisplayDate } from "@material-ui/pickers/_helpers/text-field-helper";
import Loader from "../../layouts/nav/Loader.js";
import CommonPrint from "./CommonPrint.js";
import './Print.css';
import WorkflowHeader from "./WorkflowHeader.js";
const schema = yup.object().shape({
    first_name: yup.mixed(),
    last_name: yup.mixed(),
    address: yup.mixed(),
    city: yup.mixed(),
    state: yup.mixed(),
    AL: yup.mixed(),
    mobile_phone: yup.mixed(),

    Soilcontractor: yup.string(),
    Soilassigned_date: yup.string(),
    Soilcompleted_date: yup.string(),
    Soilcost: yup.string(),
    Soildocument: yup.mixed(),
    Soilcomments: yup.string(),
    Soilstatus: yup.string(),

    Engschematics: yup.string(),
    Engcontractor: yup.string(),
    Engassigned_date: yup.string(),
    Engcompleted_date: yup.string(),
    Engcost: yup.string(),
    Engdocument: yup.mixed(),
    Engcomments: yup.string(),
    Engstatus: yup.string(),

    Inscontractor: yup.string(),
    Insassigned_date: yup.string(),
    Inscompleted_date: yup.string(),
    Inscost: yup.string(),
    Insdocument: yup.mixed(),
    Inscomments: yup.string(),
    Inscep_engg_cert: yup.string(),
    Inscep_install_cert: yup.string(),
    Insconvenant: yup.string(),
    Insothers: yup.string(),
    Insstatus: yup.string(),

    Elccontractor: yup.string(),
    Elcassigned_date: yup.string(),
    Elccompleted_date: yup.string(),
    Elccost: yup.string(),
    Elcdocument: yup.mixed(),
    Elccomments: yup.string(),
    Elcstatus: yup.string(),

    Plucontractor: yup.string(),
    Pluassigned_date: yup.string(),
    Plucompleted_date: yup.string(),
    Plucost: yup.string(),
    Pludocument: yup.mixed(),
    Plucomments: yup.string(),
    Plustatus: yup.string()
});

export default function Summary({ activeTab }) {
    const [btntitle, setbtntitle] = useState("Add Summary");
    const [cardtitle, setcardtitle] = useState("List of Summary");
    const [Showform, setShowform] = useState(false);
    const [soilttesttable, setsoilttesttable] = useState([]);
    const [activeuser, setactiveuser] = useState({});
    const [usertable, setusertable] = useState([]);
    const [soilMember, setsoilMember] = useState([]);
    const [engMember, setengMember] = useState([]);
    const [insMember, setinsMember] = useState([]);
    const [elcMember, setelcMember] = useState([]);
    const [pluMember, setpluMember] = useState([]);
    const [applicationTab, setApplicationTab] = useState("Pending");
    const [SoilStatus, setSoilStatus] = useState("Not Started");
    const [EngStatus, setSEngStatus] = useState("Not Started");
    const [InsStatus, setInsStatus] = useState("Not Started");
    const [ElectStatus, setElectStatus] = useState("Not Started");
    const [SoilStatusStyle, setSoilStatusStyle] = useState("badge rounded-pill bg-danger");
    const [EngStatusStyle, setSEngStatusStyle] = useState("badge rounded-pill bg-danger");
    const [InsStatusStyle, setInsStatusStyle] = useState("badge rounded-pill bg-danger");
    const [ElectStatusStyle, setElectStatusStyle] = useState("badge rounded-pill bg-danger");
    const [usersToBeShown, setusersToBeShown] = useState([]);
    const [currentPage, setcurrentPage] = useState(1);
    const [rowSize, setrowSize] = useState(15);
    const [sNo, setsNo] = useState(0);
    const [lastPage, setlastPage] = useState(1);
    const [searchInput, SetSearchInput] = useState("");
    const [pageSize, setpageSize] = useState(15);
    const [pageArray, setpageArray] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [member_id, setmember_id] = useState("");
    
    const { register, handleSubmit, formState: { errors }, reset, setValue, getValues, watch } = useForm({
        resolver: yupResolver(schema),
    });
    function checkboxFun(option) {
        console.log(option)
    }
    useEffect(() => {
        if (activeTab == "summary") {
            getWFSummaryList('Pending');
            getMembers();
            setbtntitle("Add Summary");
            setcardtitle("List of Summary")
            setactiveuser({})
            setShowform(false)
            setApplicationTab('Pending')
        }
    }, [activeTab]);
    const FilteredData = () => {
        return usersToBeShown.filter(
            (user) =>
                user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.first_name.toLowerCase().includes(searchInput.toLowerCase())
        );
    };
    function GetFileName(file_id) {
        const parts = file_id.split('/');
        return parts[parts.length - 1];
    }
    function downloadDocument(e, file_id) {
        e.preventDefault()
        apiServices.DownloadDocument(file_id).then(response => {
            const parts = file_id.split('/');
            //const nblob = response.blob();
            const lastPart = parts[parts.length - 1];
            //const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const blob = response.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = lastPart// encodeURIComponent(lastPart); // Encode the file name
            document.body.appendChild(a);

            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Clean up the URL
        }).catch(error => {

        })
    }
    const calculatePaginationDetails = (page, data, currPage) => {
        if(typeof page === "undefined") return;
        console.log(page)
        let users = data.filter(
            (user) =>
                user.member_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.last_name.toLowerCase().includes(searchInput.toLowerCase()) ||
                user.first_name.toLowerCase().includes(searchInput.toLowerCase()) 
        );
        let total = users.length;
        let diff = users.length % pageSize !== 0 ? 1 : 0;
        let pages = Math.floor((users.length / pageSize) + diff);
        let firstPage = 1;
        let lastPage = pages;
        let pageArray = []
        let usersToBeShown = []
        let currentPage = currPage;
        if(page?.toString()?.toLowerCase()?.indexOf('previous') > 0) {
           currentPage = currentPage - 1;
           if(currentPage < 1) {
              currentPage = 1
           }
        } else if(page?.toString()?.toLowerCase()?.indexOf('next') > 0) {
           currentPage = currentPage + 1;
           if(currentPage > pages) {
              currentPage = pages;
           }
        } else if(page?.toString()?.toLowerCase()?.indexOf('first') > 0) {
           currentPage = 1
        } else if(page?.toString()?.toLowerCase()?.indexOf('last') > 0) {
           currentPage = pages;
        } else {
           currentPage = parseInt(page);
        }
        console.log(parseInt(page))
        console.log(currentPage)
        for(let i = currentPage; i <= currentPage + (rowSize - 1); i++) {
           if(i <= pages)
           pageArray.push(i)
        }
        let currentItemIndex = ((currentPage - 1) * pageSize) ;
        // currentItemIndex = currentItemIndex === 0 ? currentItemIndex : currentItemIndex + (currentPage - 1);
        for(let i = currentItemIndex; i < currentItemIndex + rowSize && i <= (total - 1); i++) {
           usersToBeShown.push(users[i])
        }
        let sno = parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)
        let updatedState = {
           usersToBeShown: usersToBeShown,
           pageArray: pageArray,
           firstPage: firstPage,
           lastPage: lastPage,
           currentPage: currentPage,
           sNo: sno === 0 ? sno : sno - 1
        }
        console.log(updatedState)
        setusersToBeShown(usersToBeShown);
        setpageArray(pageArray);
        setcurrentPage(currentPage);
        setlastPage(lastPage);
        setsNo((parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize))+parseInt(currentPage));

        // this.setState({
        //    usersToBeShown: usersToBeShown,
        //    pageArray: pageArray,
        //    firstPage: firstPage,
        //    lastPage: lastPage,
        //    currentPage: currentPage
        // });
     }

     const handlePagination = (e, txt) => {
        e.preventDefault();
        console.log(e.target);
        // if(txt != undefined) {
           calculatePaginationDetails(txt !== undefined ? txt : e.target.text, soilttesttable, currentPage);
        // }
     }

     const onSearchChange = (e) => {
        // e.preventDefault();
        SetSearchInput(e);
        //calculatePaginationDetails(1, soilttesttable, currentPage)
        setTimeout(() => {
            if(document.querySelector(".page-link"))
            document.querySelector(".page-link").click()
        }, 1000);
     }
     useEffect(() => {
        calculatePaginationDetails(1, soilttesttable, currentPage)
     }, [searchInput, soilttesttable]);

    function downloadDocument(e,file_id) {
        e.preventDefault()
        apiServices.DownloadDocument(file_id).then(response => {
            const parts = file_id.split('/');

            const lastPart = parts[parts.length - 1];
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download =lastPart// encodeURIComponent(lastPart); // Encode the file name
            document.body.appendChild(a);
            
            a.click();
            
            document.body.removeChild(a);
            window.URL.revokeObjectURL(url); // Clean up the URL
            
        }).catch(error => {

        })
    }
    const getMembers = () => {
        apiServices.getContractorList().then(response => {
            setusertable(response.data)
            setsoilMember(response.data.filter((item) => item.scope == 'Soil Test'))
            setengMember(response.data.filter((item) => item.scope == 'Engineer'))
            setinsMember(response.data.filter((item) => item.scope == 'Installer'))
        }).catch(error => {

        })
    }
    const getWFSummaryList = (sType) => {
        setIsLoading(true);
        apiServices.getWFSummaryList(sType).then(response => {
            setIsLoading(false);
            setsoilttesttable(response.data)
            calculatePaginationDetails(1, response.data, currentPage)
        }).catch(error => {
            setIsLoading(false);
        })
    }
    function cardbtn() {
        if (btntitle === 'Add Summary' || btntitle === 'Edit Summary' || btntitle === 'View Summary') {
            setbtntitle("Back");
            setcardtitle("Add Summary")
            setactiveuser({})
            setShowform(true)
        } else {
            setbtntitle("Add Summary");
            setcardtitle("List of Summary")
            setactiveuser({})
            setShowform(false)
        }
    }
    function initiateSummary() {
            setbtntitle("Add Summary");
            setcardtitle("List of Summary")
            setactiveuser({})
            setShowform(false)
    }

    function Print(elem) {
        var mywindow = window.open('Iwashwell', 'PRINT', 'height=400,width=600');

        // mywindow.document.write('<html><head><title>' + document.title  + '</title>');
        // mywindow.document.write('</head><body >');
        // mywindow.document.write('<h1>' + document.title  + '</h1>');

        mywindow.document.head.innerHTML += '<link rel="stylesheet" type="text/css" href="./css/style.css">';

        mywindow.document.write(document.getElementById('printableSummarydiv').innerHTML);
        // mywindow.document.write('</body></html>');

        mywindow.document.close(); // necessary for IE >= 10
        mywindow.focus(); // necessary for IE >= 10*/

        mywindow.print();
        mywindow.close();

    }
    function viewuser(e, user) {
        e.preventDefault()
        BindAllValues(user.member_id);
        setmember_id(user.member_id);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
            setactiveuser(getValues());
        }).catch(error => {

        })
        setbtntitle("Back");
        setcardtitle("View Summary")

        setShowform(true)
    }
    const ConvertToDate = (sDateTime) => {
        //const dateString = '2024-01-06T07:02:23.033974';
        const dateObject = new Date(sDateTime);

        const day = dateObject.getDate();
        const month = dateObject.getMonth() + 1;
        const year = dateObject.getFullYear();

        // Add leading zeros if needed
        const formattedDay = day < 10 ? `0${day}` : day;
        const formattedMonth = month < 10 ? `0${month}` : month;

        return `${formattedDay}-${formattedMonth}-${year}`;
    }
    const BindAllValues = (member_id) => {
        setSoilStatus("Not Started");
        setElectStatus("Not Started");
        setSEngStatus("Not Started");
        setInsStatus("Not Started");
        setSoilStatusStyle("badge rounded-pill bg-danger");
        setElectStatusStyle("badge rounded-pill bg-danger");
        setSEngStatusStyle("badge rounded-pill bg-danger");
        setInsStatusStyle("badge rounded-pill bg-danger");
        apiServices.getWFSummaryDetails(member_id).then(response => {
            if (response.data.soil_test && response.data.soil_test.length > 0) {
                const contractorValue = response.data.soil_test[0].contractor_id;
                if (contractorValue) {
                    setValue('Soilcontractor', response.data.soil_test[0].contractor_id);
        }  else {

            setValue('Soilcontractor', '');
        }
                setValue('Soilassigned_date', response.data.soil_test[0].assigned_date);
                setValue('Soilcost', response.data.soil_test[0].cost);
                setValue('Soilcomments', response.data.soil_test[0].comments);
                setValue('Soilstatus', response.data.soil_test[0].status);
                setValue('Soildocument', response.data.soil_test[0].document);
                
                if (response.data.soil_test[0].status == "Approved") {
                    setValue('Soilcompleted_date', ConvertToDate(response.data.soil_test[0].updated_at));
                    setSoilStatus("Completed");
                    setSoilStatusStyle("badge rounded-pill bg-success");
                } else {
                    setSoilStatus("In Progress");
                    setSoilStatusStyle("badge rounded-pill bg-secondary");
                    setValue('Soilcompleted_date', '');
                }
            } else {
                // Optional: Clear any existing values if the array is empty
                setValue('Soilcontractor', '');
                setValue('Soilassigned_date', '');
                setValue('Soilcost', '');
                setValue('Soilcomments', '');
                setValue('Soilstatus', '');
                setValue('Soilcompleted_date', '');
                setValue('Soildocument', '');
            }

            if (response.data.engg_design && response.data.engg_design.length > 0) {
                const contractorValue = response.data.engg_design[0].contractor_id;
                if (contractorValue) {
                    setValue('Engcontractor', response.data.engg_design[0].contractor_id);
                } else {
                    setValue('Engcontractor', '');
                }
            setValue('Engassigned_date', response.data.engg_design[0].assigned_date);
            setValue('Engcost', response.data.engg_design[0].cost);
            setValue('Engcomments', response.data.engg_design[0].comments);
            setValue('Engstatus', response.data.engg_design[0].status);
            setValue('Engschematics', response.data.engg_design[0].schematics);
            
            setValue('Engdocument', response.data.engg_design[0].revised_schematics);

            if (response.data.engg_design[0].status == "Approved") {
                setValue('Engcompleted_date', ConvertToDate(response.data.engg_design[0].updated_at));
                setSEngStatus("Completed");
                setSEngStatusStyle("badge rounded-pill bg-success");
            } else {
                setSEngStatus("In Progress");
                setSEngStatusStyle("badge rounded-pill bg-secondary");
            }
        } else {
            setValue('Engcontractor', '');
            setValue('Engassigned_date', '');
            setValue('Engcost', '');
            setValue('Engcomments', '');
            setValue('Engstatus', '');
            setValue('Engschematics', '');
            setValue('Engdocument', '');
            setValue('Engcompleted_date','');
        }

        if (response.data.installation && response.data.installation.length > 0) {
            const contractorValue = response.data.installation[0].contractor_id;
            if (contractorValue) {
                setValue('Inscontractor', response.data.installation[0].contractor_id);
            } else {
                setValue('Inscontractor', '');
            }
            setValue('Insassigned_date', response.data.installation[0].assigned_date);
            setValue('Inscost', response.data.installation[0].cost);
            setValue('Inscomments', response.data.installation[0].comments);
            setValue('Insstatus', response.data.installation[0].status);
            setValue('Inscep_engg_cert', response.data.installation[0].cep_engg_cert);
            setValue('Inscep_install_cert', response.data.installation[0].cep_install_cert);
            setValue('Insconvenant', response.data.installation[0].convenant);
            setValue('Insothers', response.data.installation[0].others);
            if (response.data.installation[0].status == "Approved") {
                setValue('Inscompleted_date', ConvertToDate(response.data.installation[0].updated_at));
                setInsStatus("Completed");
                setInsStatusStyle("badge rounded-pill bg-success")
            } else {
                setInsStatus("In Progress");
                setInsStatusStyle("badge rounded-pill bg-secondary")
            }
        } else {
            setValue('Inscontractor', '');
            setValue('Insassigned_date', '');
            setValue('Inscost', '');
            setValue('Inscomments', '');
            setValue('Insstatus', '');
            setValue('Inscep_engg_cert', '');
            setValue('Inscep_install_cert', '');
            setValue('Insconvenant', '');
            setValue('Insothers', '');
            setValue('Inscompleted_date', '');
        }

        if (response.data.electrical_plumbing && response.data.electrical_plumbing.length > 0) {
            const contractorValue = response.data.electrical_plumbing[0].elect_contractor;
            if (contractorValue) {
                setValue('Elccontractor', response.data.electrical_plumbing[0].elect_contractor);
            } else {
                setValue('Elccontractor', '');
            }
            setValue('Elcassigned_date', response.data.electrical_plumbing[0].elect_assigned_date);
            setValue('Elccost', response.data.electrical_plumbing[0].elect_cost);
            setValue('Elccomments', response.data.electrical_plumbing[0].comments);
            setValue('Elcstatus', response.data.electrical_plumbing[0].status);
            setValue('Elcdocument', response.data.electrical_plumbing[0].elect_document);
            if (response.data.electrical_plumbing[0].status == "Approved") {
                setValue('Elccompleted_date', ConvertToDate(response.data.electrical_plumbing[0].updated_at));
                setValue('Plucompleted_date', ConvertToDate(response.data.electrical_plumbing[0].updated_at));
                setElectStatus("Completed");
                setElectStatusStyle("badge rounded-pill bg-success")
            } else {
                setElectStatus("In Progress");
                setElectStatusStyle("badge rounded-pill bg-secondary")
            }
            const contractorValue1 = response.data.electrical_plumbing[0].plumb_contractor;
            if (contractorValue1) {
                setValue('Plucontractor', response.data.electrical_plumbing[0].plumb_contractor);
            } else {
                setValue('Plucontractor', '');
            }
            setValue('Pluassigned_date', response.data.electrical_plumbing[0].plumb_assigned_date);
            setValue('Plucost', response.data.electrical_plumbing[0].plumb_cost);
            setValue('Plucomments', response.data.electrical_plumbing[0].comments);
            setValue('Plustatus', response.data.electrical_plumbing[0].status);
            setValue('Pludocument', response.data.electrical_plumbing[0].plumb_document);

        } else {
            setValue('Elccontractor', '');
            setValue('Elcassigned_date', '');
            setValue('Elccost', '');
            setValue('Elccomments', '');
            setValue('Elcstatus', '');
            setValue('Elcdocument', '');
            setValue('Plucontractor', '');
            setValue('Pluassigned_date', '');
            setValue('Plucost', '');
            setValue('Plucomments', '');
            setValue('Plustatus', '');
            setValue('Pludocument', '');
            setValue('Elccompleted_date', '');
            setValue('Plucompleted_date', '');
    }

            setactiveuser(getValues());

        }).catch(error => {

        })
    }
    function edituser(e, user) {
        e.preventDefault()
        BindAllValues(user.member_id);
        apiServices.getMemberHeaderList(user.member_id).then(response => {
            setValue('first_name', response.data[0].first_name);
            setValue('last_name', response.data[0].last_name);
            setValue('address', response.data[0].address);
            setValue('mobile_phone', response.data[0].mobile_phone);
            setValue('state', response.data[0].state);
            setValue('city', response.data[0].city);
            setValue('AL', response.data[0].AL);
        }).catch(error => {

        })

        setbtntitle("Back");
        setcardtitle("Edit Summary")
        setactiveuser(user)
        setShowform(true)
    }

    function deleteuser(e, user) {
        e.preventDefault()
        setactiveuser(user)
        notifyError();
    }

    const notifyError = () => {
        toast.error("Summary Deleted", {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
    };

    return (
        <>
            {isLoading ?
                <Loader />
                :
                <>
                    <div>
                        <div style={{ display: 'none' }} className="pdf-page" id="printableSummarydiv">
                            <div className="basic-form">
                                <CommonPrint></CommonPrint>
                                <p>View Summary</p>
                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6',width:'100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold',width:'200PX' }}>First Name:</td>
                                            <td>{activeuser.first_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Last Name:</td>
                                            <td>{activeuser.last_name}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Address:</td>
                                            <td>{activeuser.address}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Phone:</td>
                                            <td>{activeuser.mobile_phone}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>State:</td>
                                            <td>{activeuser.state}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>City:</td>
                                            <td>{activeuser.city}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Zip Code:</td>
                                            <td>{activeuser.AL}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr></hr>
                                <p>Soil Test</p>
                                
                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6',width:'100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold',width:'200PX' }}>Assigned Date:</td>
                                            <td>{activeuser.Soilassigned_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Completed Date:</td>
                                            <td>{activeuser.Soilcompleted_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                            <td>{activeuser.site_id}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Cost:</td>
                                            <td>{activeuser.Soilcost}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Comments:</td>
                                            <td>{activeuser.Soilcomments}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Upload Documents:</td>
                                            <td>{activeuser.Soildocument}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr></hr>
                                <p>Engineering Design</p>
                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6',width:'100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold',width:'200PX' }}>Schematics:</td>
                                            <td>{activeuser.Engschematics}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Engineer Name:</td>
                                            <td>{activeuser.Engcontractor}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                            <td>{activeuser.Engassigned_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Completed Date:</td>
                                            <td>{activeuser.Engcompleted_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Cost:</td>
                                            <td>{activeuser.Engcost}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Comments:</td>
                                            <td>{activeuser.Engcomments}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Revised Schematics:</td>
                                            <td>{activeuser.Engdocument}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr></hr>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <br></br>
                                <p>Installation</p>
                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6',width:'100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold',width:'200PX' }}>Engineer:</td>
                                            <td>{activeuser.Inscontractor}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                            <td>{activeuser.Insassigned_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Completed Date:</td>
                                            <td>{activeuser.Inscompleted_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Cost:</td>
                                            <td>{activeuser.Inscost}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Comments:</td>
                                            <td>{activeuser.Inscomments}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>Attachments Upload</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>CEP - Installation Certificate:</td>
                                            <td>{activeuser.Insdocument}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>CEP6 - Engineer Certificate:</td>
                                            <td>{activeuser.Insdocument}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Convenant:</td>
                                            <td>{activeuser.Insdocument}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Others:</td>
                                            <td>{activeuser.Insdocument}</td>
                                        </tr>
                                    </tbody>
                                </table>
                                <hr></hr>
                                <p>Electrical & Plumbing</p>
                                <table className="table col-md-12" style={{ border: '1px solid #dee2e6',width:'100%' }}>
                                    <tbody>
                                        <tr>
                                            <td style={{ fontWeight: 'bold',width:'200PX' }}>Electrical Contractor:</td>
                                            <td>{activeuser.Elccontractor}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                            <td>{activeuser.Elcassigned_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Completed Date:</td>
                                            <td>{activeuser.Elccompleted_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Cost:</td>
                                            <td>{activeuser.Elccost}</td>
                                        </tr>

                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Plumbing Contractor:</td>
                                            <td>{activeuser.Plucontractor}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Assigned Date:</td>
                                            <td>{activeuser.Pluassigned_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Completed Date:</td>
                                            <td>{activeuser.Plucompleted_date}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Cost:</td>
                                            <td>{activeuser.Plucost}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>Comments:</td>
                                            <td>{activeuser.Elccomments}</td>
                                        </tr>
                                        <tr>
                                            <td style={{ fontWeight: 'bold' }}>CEP - Installation Certificate:</td>
                                            <td>{activeuser.Elcdocument}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                    <Tab.Container defaultActiveKey={applicationTab} activeKey={applicationTab}>
                        <Nav as="ul" className="nav-tabs">
                            <Nav.Item as="li">
                                <Nav.Link eventKey="Pending" disabled={applicationTab=='Pending'?true:false} onClick={() => { getWFSummaryList('Pending'); setApplicationTab('Pending');SetSearchInput("");initiateSummary(); }}>
                                    <i className={`flaticon-381-windows me-2`} />
                                    In Prgress
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Nav.Link eventKey="Approved" disabled={applicationTab=='Approved'?true:false} onClick={() => { getWFSummaryList('Approved'); setApplicationTab('Approved');SetSearchInput("");initiateSummary(); }}>
                                    <i className={`flaticon-381-windows me-2`} />
                                    Completed
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                        <Tab.Content className="pt-4">
                            <Tab.Pane eventKey="Pending">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <h4 className="card-title">{cardtitle}</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 text-end">
                                        {/* <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                    {btntitle}
                </button> */}
                                    </div>
                                </div>
                                <div className="row">
                                    {Showform === true
                                        ?
                                        <div>
                                            <div>
                                            <WorkflowHeader activeMember={member_id}></WorkflowHeader>
                                                <div className="row">
                                                    {/* <div className="form-group mb-3 col-md-4">
                                                        <label>First Name</label>
                                                        <input
                                                            {...register("first_name")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="First Name"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['FIRST NAME']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Last Name</label>
                                                        <input
                                                            {...register("last_name")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Last Name"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['LAST NAME']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Address</label>
                                                        <input
                                                            {...register("address")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Address"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ADDRESS']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Phone</label>
                                                        <input
                                                            {...register("mobile_phone")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Phone"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['PHONE NO']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>State</label>
                                                        <input
                                                            {...register("state")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="State"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['STATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>City</label>
                                                        <input
                                                            {...register("city")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="City"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['CITY']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Zipcode</label>
                                                        <input
                                                            {...register("AL")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Zipcode"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ZIP CODE']}
                                                        />
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Soil Test &nbsp; </h4>  <span className={SoilStatusStyle}> {SoilStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned To</label>
                                                        <select
                                                            {...register("Soilcontractor")}
                                                            // defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['PROFESSIONAL NAME']}
                                                            // value={watch("Soilcontractor") || ""}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {soilMember.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Soilassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assigned Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Soilcompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['Completed DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Soilcost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Soilcomments")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Upload Documents</label>
                                                        {activeuser.Soildocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Soildocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Soildocument)}</a>
                                                        </div>}
                                                        <input style={{display:'none'}} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Engineering Design &nbsp;</h4><span className={EngStatusStyle}> {EngStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Schematics</label>
                                                        <input
                                                            {...register("Engschematics")}
                                                            // defaultValue={"option"}
                                                            // id="SponsorinputState"
                                                            // className="form-control"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="schematics"
                                                            // value={activeuser['SCHEMATICS']}
                                                            value={watch("Engschematics") || ""}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        />
                                                        {/* </select> */}
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Engineer Name</label>
                                                        <select
                                                            {...register("Engcontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            // value={activeuser['ENGINEER']}
                                                            value={watch("Engcontractor") || ""}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {engMember.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Engassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Engcompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Engcost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Engcomments")}
                                                            rows="10"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']} />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Revised Schematics</label>
                                                        {activeuser.Engdocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Engdocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Engdocument)}</a>
                                                        </div>}
                                                        <input type="file" style={{display:'none'}} className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Installation &nbsp;</h4><span className={InsStatusStyle}> {InsStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Engineer</label>
                                                        <select
                                                            {...register("Inscontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['ASSIGN TO']}
                                                            // value={watch("Inscontractor") || ""}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {insMember.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Insassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Inscompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Inscost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Inscomments")}
                                                            rows="10"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']} />
                                                    </div>
                                                    {/* <div className="form-group mb-3 col-md-4">
                                                <label>Document for Installation</label>
                                                <input type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                            </div> */}
                                                </div>
                                                <div className="row">
                                                    <h4>Attachments Upload</h4>
                                                    <div className="row">
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >CEP - Installation Certificate &nbsp;</label>
                                                            </div>
                                                            {activeuser.Inscep_install_cert && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Inscep_install_cert)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Inscep_install_cert)}</a>
                                                        </div>}
                                                            <div className="form_file_input" style={{display:'none'}}>
                                                                <div className="input-group">
                                                                    <div className="from-file">
                                                                        <input {...register("cep_install_cert")} type="file" className="form-file-input" disabled={cardtitle === "View Summary" ? true : false} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >CEP6 - Engineer Certificate &nbsp;</label>
                                                            </div>
                                                            {activeuser.Inscep_engg_cert && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Inscep_engg_cert)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Inscep_engg_cert)}</a>
                                                        </div>}
                                                            <div className="form-check custom-checkbox" style={{display:'none'}}>
                                                                <input {...register("cep_engg_cert")} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >Convenant &nbsp;</label>
                                                            </div>
                                                            {activeuser.Insconvenant && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Insconvenant)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Insconvenant)}</a>
                                                        </div>}
                                                            <div className="form-check custom-checkbox" style={{display:'none'}}>
                                                                <input {...register("convenant")} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >Others &nbsp;</label>
                                                            </div>
                                                            {activeuser.Insothers && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Insothers)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Insothers)}</a>
                                                        </div>}
                                                            <div className="form-check custom-checkbox" style={{display:'none'}}>
                                                                <input {...register("others")} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Electrical & Plumbing &nbsp;</h4><span className={ElectStatusStyle}> {ElectStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Electrical Contractor</label>
                                                        <select
                                                            {...register("Elccontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['ELECTRICAL CONTRACTOR']}
                                                            // value={watch("Elccontractor") || ""}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {usertable.map((item, i) => {
                                                            if (item.scope == 'Electrical') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Elcassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Elccompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Elccost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Electrical Documents</label>
                                                        {activeuser.Elcdocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Elcdocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Elcdocument)}</a>
                                                        </div>}
                                                        <input type="file" style={{display:'none'}} className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Plumbing Contractor</label>
                                                        <select
                                                            {...register("Plucontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['PLUMBING CONTRACTOR']}
                                                            // value={watch("Plucontractor") || ""}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {usertable.map((item, i) => {
                                                            if (item.scope == 'Plumbing') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Pluassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Plucompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Plucost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Plucomments")}
                                                            rows="10"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']} />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Plumbing Documents</label>
                                                        {activeuser.Pludocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Pludocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Pludocument)}</a>
                                                        </div>}
                                                        <input type="file" style={{display:'none'}} className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="form-group mb-3 col-md-3">
                        </div> */}
                                                    {/* <div className="form-group mb-3 col-md-4 text-center">
                                <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                    Save
                                </button>
                            </div> */}
                                                    <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                            </div>
                                                            <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                        </div>
                                                        <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                        </div>
                                                        <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                        </div>
                                                    <div className="col-md-2 form-group mb-3 text-center">
                                                        <button className="btn btn-warning" onClick={cardbtn}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-2 text-center">
                                                        <button className="btn btn-success" onClick={Print}>
                                                            Print
                                                        </button>
                                                    </div>
                                                    {/* <HeaderPrint></HeaderPrint> */}
                                                    {/* <div className="form-group mb-3 col-md-3"></div> */}
                                                </div>
                                            </div>
                                        </div>

                                        : 
                                        <>
                                        <div className="basic-form">
                                           <div className="row">
                                               <div className="form-group mb-3 col-md-4">
                                                   <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                               </div>
                                           </div>
                                       </div>
                                       <br/>
                                       <Table responsive className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>S NO.</strong>
                                                    </th>
                                                    <th>
                                                        <strong>NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>FIRST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>LAST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Actions</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                    return [
                                                        <tr key={i}>
                                                            <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                            <td>{item.member_name}</td>
                                                            <td>{item.first_name}</td>
                                                            <td>{item.last_name}</td>
                                                            <td><div className="d-flex">
                                                                <Link
                                                                    onClick={(e) => viewuser(e, item)}
                                                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </Link>
                                                                {/* <Link
                           onClick={(e)=> edituser(e,item)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link> */}
                                                                {/* <Link
                           onClick={(e)=> deleteuser(e,item)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link> */}
                                                            </div></td>
                                                        </tr>
                                                    ];
                                                })}
                                            </tbody>
                                        </Table>
                                        <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                    }
                                </div>
                            </Tab.Pane>
                            <Tab.Pane eventKey="Approved">
                                <div className="row">
                                    <div className="col-xl-6 col-lg-6">
                                        <h4 className="card-title">{cardtitle}</h4>
                                    </div>
                                    <div className="col-xl-6 col-lg-6 text-end">
                                        {/* <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                    {btntitle}
                </button> */}
                                    </div>
                                </div>
                                <div className="row">
                                    {Showform === true
                                        ?
                                        <div>
                                            <div>
                                            <WorkflowHeader activeMember={member_id}></WorkflowHeader>
                                                <div className="row">
                                                    {/* <div className="form-group mb-3 col-md-4">
                                                        <label>First Name</label>
                                                        <input
                                                            {...register("first_name")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="First Name"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['FIRST NAME']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Last Name</label>
                                                        <input
                                                            {...register("last_name")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Last Name"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['LAST NAME']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Address</label>
                                                        <input
                                                            {...register("address")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Address"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ADDRESS']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Phone</label>
                                                        <input
                                                            {...register("mobile_phone")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Phone"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['PHONE NO']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>State</label>
                                                        <input
                                                            {...register("state")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="State"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['STATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>City</label>
                                                        <input
                                                            {...register("city")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="City"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['CITY']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Zipcode</label>
                                                        <input
                                                            {...register("AL")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Zipcode"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ZIP CODE']}
                                                        />
                                                    </div> */}
                                                </div>
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Soil Test &nbsp; </h4>  <span className={SoilStatusStyle}> {SoilStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned To</label>
                                                        <select
                                                            {...register("Soilcontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['PROFESSIONAL NAME'] || "Lucy McKenzie"}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {soilMember.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Soilassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assigned Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Soilcompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['Completed DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Soilcost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Soilcomments")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Upload Documents</label>
                                                        {activeuser.Soildocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Soildocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Soildocument)}</a>
                                                        </div>}
                                                        <input style={{display:'none'}} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Engineering Design &nbsp;</h4><span className={EngStatusStyle}> {EngStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Schematics</label>
                                                        <input
                                                            {...register("Engschematics")}
                                                            // defaultValue={"option"}
                                                            // id="SponsorinputState"
                                                            // className="form-control"
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="schematics"
                                                            value={activeuser['SCHEMATICS']}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        />
                                                        {/* </select> */}
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Engineer Name</label>
                                                        <select
                                                            {...register("Engcontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['ENGINEER']}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {engMember.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Engassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Engcompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Engcost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Engcomments")}
                                                            rows="10"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']} />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Revised Schematics</label>
                                                        {activeuser.Engdocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Engdocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Engdocument)}</a>
                                                        </div>}
                                                        <input type="file" style={{display:'none'}} className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Installation &nbsp;</h4><span className={InsStatusStyle}> {InsStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Engineer</label>
                                                        <select
                                                            {...register("Inscontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['ASSIGN TO']}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {insMember.map((item, i) => (
                                                            <option key={item.id} value={item.id}>
                                                                {item.first_name} {item.last_name}
                                                            </option>
                                                        ))}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Insassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Inscompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Inscost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Inscomments")}
                                                            rows="10"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']} />
                                                    </div>
                                                    {/* <div className="form-group mb-3 col-md-4">
                                                <label>Document for Installation</label>
                                                <input type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                            </div> */}
                                                </div>
                                                <div className="row">
                                                    <h4>Attachments Upload</h4>
                                                    <div className="row">
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >CEP - Installation Certificate &nbsp;</label>
                                                            </div>
                                                            {activeuser.Inscep_install_cert && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Inscep_install_cert)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Inscep_install_cert)}</a>
                                                        </div>}
                                                            <div className="form_file_input" style={{display:'none'}}>
                                                                <div className="input-group">
                                                                    <div className="from-file">
                                                                        <input {...register("cep_install_cert")} type="file" className="form-file-input" disabled={cardtitle === "View Summary" ? true : false} />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >CEP6 - Engineer Certificate &nbsp;</label>
                                                            </div>
                                                            {activeuser.Inscep_engg_cert && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Inscep_engg_cert)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Inscep_engg_cert)}</a>
                                                        </div>}
                                                            <div className="form-check custom-checkbox" style={{display:'none'}}>
                                                                <input {...register("cep_engg_cert")} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >Convenant &nbsp;</label>
                                                            </div>
                                                            {activeuser.Insconvenant && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Insconvenant)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Insconvenant)}</a>
                                                        </div>}
                                                            <div className="form-check custom-checkbox" style={{display:'none'}}>
                                                                <input {...register("convenant")} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                            </div>
                                                        </div>
                                                        <div className="btn-group pt-1 col-md-12">
                                                            <div className="form-check custom-checkbox">
                                                                <label
                                                                    className="form-check-label"
                                                                    htmlFor="checkbox1"
                                                                >Others &nbsp;</label>
                                                            </div>
                                                            {activeuser.Insothers && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Insothers)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Insothers)}</a>
                                                        </div>}
                                                            <div className="form-check custom-checkbox" style={{display:'none'}}>
                                                                <input {...register("others")} type="file" className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <hr />
                                                <div className="row">
                                                    <p style={{ display: 'flex' }}><h4>Electrical & Plumbing &nbsp;</h4><span className={ElectStatusStyle}> {ElectStatus}</span></p>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Electrical Contractor</label>
                                                        <select
                                                            {...register("Elccontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['ELECTRICAL CONTRACTOR'] || "Milton"}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {usertable.map((item, i) => {
                                                            if (item.scope == 'Electrical') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Elcassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Elccompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Elccost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Electrical Documents</label>
                                                        {activeuser.Elcdocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Elcdocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Elcdocument)}</a>
                                                        </div>}
                                                        <input type="file" style={{display:'none'}} className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Plumbing Contractor</label>
                                                        <select
                                                            {...register("Plucontractor")}
                                                            defaultValue={"option"}
                                                            id="SponsorinputState"
                                                            className="form-control"
                                                            value={activeuser['PLUMBING CONTRACTOR'] || "Jayme"}
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                        >
                                                            {usertable.map((item, i) => {
                                                            if (item.scope == 'Plumbing') {
                                                                return (
                                                                    <option key={item.id} value={item.id}>
                                                                        {item.first_name} {item.last_name}
                                                                    </option>)
                                                            }

                                                        })}
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Assigned Date</label>
                                                        <input
                                                            {...register("Pluassigned_date")}
                                                            type="date"
                                                            className="form-control"
                                                            placeholder="Assign Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['ASSIGNED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Completed Date</label>
                                                        <input
                                                            {...register("Plucompleted_date")}
                                                            //type="date"
                                                            className="form-control"
                                                            placeholder="Completed Date"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMPLETED DATE']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Cost</label>
                                                        <input
                                                            {...register("Plucost")}
                                                            type="text"
                                                            className="form-control"
                                                            placeholder="cost"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COST']}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Comments</label>
                                                        <textarea
                                                            {...register("Plucomments")}
                                                            rows="10"
                                                            className="form-control"
                                                            placeholder="Comments"
                                                            disabled={cardtitle === "View Summary" ? true : false}
                                                            value={activeuser['COMMENTS']} />
                                                    </div>
                                                    <div className="form-group mb-3 col-md-4">
                                                        <label>Plumbing Documents</label>
                                                        {activeuser.Pludocument && <div className="form-group mb-3 col-md-4">
                                                        <a style={{ color: 'blue' }} onClick={(e) => downloadDocument(e, activeuser.Pludocument)} href="#"><i className="fa fa-download"></i> {GetFileName(activeuser.Pludocument)}</a>
                                                        </div>}
                                                        <input type="file" style={{display:'none'}} className="makebtnleft" disabled={cardtitle === "View Summary" ? true : false} />
                                                    </div>
                                                </div>
                                                <div className="row">
                                                    {/* <div className="form-group mb-3 col-md-3">
                        </div> */}
                                                    {/* <div className="form-group mb-3 col-md-4 text-center">
                                <button type="submit" className="btn btn-primary" onClick={(e) => cardbtn(e)}>
                                    Save
                                </button>
                            </div> */}
                                                    <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                            </div>
                                                            <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                        </div>
                                                        <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                        </div>
                                                        <div className="form-group mb-3 col-md-2 text-center" style={{display: "block"}}>
                                                            
                                                        </div>
                                                    <div className="col-md-2 form-group mb-3 text-center">
                                                        <button className="btn btn-warning" onClick={cardbtn}>
                                                            Cancel
                                                        </button>
                                                    </div>
                                                    <div className="form-group mb-3 col-md-2 text-center">
                                                        <button className="btn btn-success" onClick={Print}>
                                                            Print
                                                        </button>
                                                    </div>
                                                    {/* <HeaderPrint></HeaderPrint> */}
                                                    {/* <div className="form-group mb-3 col-md-3"></div> */}
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        <>
                                        <div className="basic-form">
                                           <div className="row">
                                               <div className="form-group mb-3 col-md-4">
                                                   <input type="text" className="form-control" placeholder="Search" value={searchInput} onChange={(e) => onSearchChange(e.target.value)} />
                                               </div>
                                           </div>
                                       </div>
                                       <br/>
                                       <Table responsive className="table table-striped">
                                            <thead>
                                                <tr>
                                                    <th>
                                                        <strong>S NO.</strong>
                                                    </th>
                                                    <th>
                                                        <strong>NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>FIRST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>LAST NAME</strong>
                                                    </th>
                                                    <th>
                                                        <strong>Actions</strong>
                                                    </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {FilteredData().map((item, i) => {
                            console.log("mykeys", i, parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize));
                                                    return [
                                                        <tr key={i}>
                                                             <td>{(parseInt(currentPage) * parseInt(pageSize)  - parseInt(pageSize)) + 1 +i}</td>
                                                            <td>{item.member_name}</td>
                                                            <td>{item.first_name}</td>
                                                            <td>{item.last_name}</td>
                                                            <td><div className="d-flex">
                                                                <Link
                                                                    onClick={(e) => viewuser(e, item)}
                                                                    className="btn btn-warning shadow btn-xs sharp me-1"
                                                                >
                                                                    <i className="fa fa-eye"></i>
                                                                </Link>
                                                                {/* <Link
                           onClick={(e)=> edituser(e,item)}
                          className="btn btn-primary shadow btn-xs sharp me-1"
                        >
                          <i className="fa fa-pencil"></i>
                        </Link> */}
                                                                {/* <Link
                           onClick={(e)=> deleteuser(e,item)}
                          className="btn btn-danger shadow btn-xs sharp"
                        >
                          <i className="fa fa-trash"></i>
                        </Link> */}
                                                            </div></td>
                                                        </tr>
                                                    ];
                                                })}
                                            </tbody>
                                        </Table>
                                        <br/>
                    {FilteredData().length > 0 &&
                    <Pagination>
                        <Pagination.First onClick={(e) => handlePagination(e, ">First")} />
                        <Pagination.Prev onClick={(e) => handlePagination(e, ">Previous")} />{
                        pageArray && pageArray.length &&
                        pageArray.map(
                            (item) => (
                                <Pagination.Item key={item} onClick={(e) => handlePagination(e)}
                                active={currentPage == item}>{item}</Pagination.Item>
                            )
                        )
                        }
                        <Pagination.Next onClick={(e) => handlePagination(e, ">Next")} />
                        <Pagination.Last onClick={(e) => handlePagination(e, ">Last")} />
                    </Pagination>
                    }
                    </>
                                    }
                                </div>
                            </Tab.Pane>
                        </Tab.Content>
                    </Tab.Container>
                </>
            }

        </>
    )
}
